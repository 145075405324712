import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import withLocation from 'components/shared/with-location'
import useAuth from 'context/auth/use'

const LoginHomeowner = (
  props: RouteComponentProps<{ search: { email: string } }>,
) => {
  const { login } = useAuth()

  useEffect(() => {
    login({
      login_hint: props.search?.email || '',
      appState: { initialLogin: true },
      audience: process.env.GATSBY_AUTH0_AUDIENCE,
      scope:
        'openid profile email read:assets write:assets read:others write:others',
    })
  }, [])

  return <Loader />
}

export default withLocation(LoginHomeowner)
